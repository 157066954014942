<template>
  <div class="card">
    <a-page-header
      :style="'border: 1px solid rgb(235, 237, 240);'"
      :title="activity.designation"
      :sub-title="`Service:${activityName} | Nombre élèves: ${activityFees.length}`"
      id="header-id"
      class="card-header"
      @back="() => $router.go(-1)"
    >
      <div class="row">
        <div class="col-md-4"><b>Order: </b>{{ activity.order }}</div>
        <div class="col-md-4">
          <b>{{ $t("all.startDate") }}: </b
          >{{ moment(activity.start).format("DD-MM-YYYY") }}
        </div>
        <div class="col-md-4">
          <b>{{ $t("all.endDate") }}: </b
          >{{
            activity.start ? moment(activity.end).format("DD-MM-YYYY") : "-"
          }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <b>{{ $t("recette.fraisInscri") }}: </b>{{ activity.inscription }}DT
        </div>
        <div class="col-md-4">
          <b>{{ $t("recette.fraisMensuel") }}: </b
          >{{ activity.once ? "Non" : activity.monthly + "DT" }}
        </div>
        <div class="col-md-4"></div>
      </div>
      <div class="row mt-5">
        <div class="col-md-4" v-if="!activity.once">
          <a-select
            id="selectMonth"
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 100%"
            @change="
              (val) => {
                handleFilterChangeV2('month', val);
              }
            "
          >
            <a-select-option value="all"> Tous les mois </a-select-option>
            <a-select-option
              v-for="(value, key) in frMonths"
              :key="key"
              :value="key"
            >
              {{ value }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-md-4">
          <a-select
            id="selectClass"
            show-search
            :placeholder="$t('emploi.listeClasses')"
            option-filter-prop="children"
            :filter-option="filterOption"
            style="width: 100%"
            @change="
              (val) => {
                handleFilterChangeV2('classe', val);
              }
            "
          >
            <a-select-option value="all"> Tous les classes </a-select-option>
            <a-select-option
              v-for="classe in classList"
              :key="classe._id"
              :value="classe._id"
            >
              {{ classe.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-md-4" v-if="!activity.once">
          <a-button type="file-pdf" @click="generatePdf" style="width: 100%">
            <a-icon type="file-pdf" @click="generatePdf" />
            {{ $t("action.imprimerTab") }}
          </a-button>
        </div>
        <div class="col-md-4 offset-md-4" v-else>
          <a-button type="file-pdf" @click="generatePdf" style="width: 100%">
            <a-icon type="file-pdf" @click="generatePdf" />
            {{ $t("action.imprimerTab") }}
          </a-button>
        </div>
      </div>
    </a-page-header>

    <div class="card-body">
      <div class="d-inline m-2">
        <a-tag color="green">
          <b>Payé </b>
        </a-tag>
        <a-tag color="red">
          <b>Impayé </b>
        </a-tag>
      </div>
      <a-table
        :data-source="activeDataTable"
        :loading="tableLeading"
        :columns="columns"
        :pagination="true"
        rowKey="_id"
      >
        <template
          slot="expandedRowRender"
          slot-scope="record"
          style="margin: 0"
        >
          <div class="d-inline">
            <a-tag
              :color="record.inscription ? 'green' : 'red'"
              v-if="activity.inscription > 0"
            >
              <b v-if="record.inscription">Inscription payé</b>
              <b v-else>Inscription Non payé</b>
            </a-tag>
          </div>
          <div
            v-for="(month, key, index) in record.months"
            :key="index"
            class="d-inline"
          >
            <a-tag :color="month ? 'green' : 'red'">
              <b v-if="month"
                >{{ frMonths[parseInt(key.split("-")[1])] }} payé
              </b>
              <b v-else
                >{{ frMonths[parseInt(key.split("-")[1])] }} Non payé
              </b>
              <small>({{ paymentDate(record.start, key) }})</small>
            </a-tag>
            <div v-if="index % 4 == 3"><br /></div>
          </div>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag :color="record.status == 'payed' ? 'green' : 'red'">
            <b v-if="record.status == 'payed'">Payé</b>
            <b v-else>Impayés</b>
          </a-tag>
        </template>
        <template slot="monthlyStatus" slot-scope="text, record">
          <div
            v-for="(month, key, index) in record.months"
            :key="index"
            class="d-inline"
          >
            <a-tag :color="month ? 'green' : 'red'">
              <b v-if="month">{{ frMonths[parseInt(key.split("-")[1])] }} </b>
              <b v-else>{{ frMonths[parseInt(key.split("-")[1])] }} </b>
              <small>({{ paymentDate(record.start, key) }})</small>
            </a-tag>
            <div v-if="index % 4 == 3"><br /></div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { PageHeader, Descriptions } from "ant-design-vue";
import moment from "moment";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";

const columns = [
  {
    title: "Nom de l'élève",
    dataIndex: "fullName",
    key: "fullName",
    scopedSlots: {
      customRender: "fullName",
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
    },
  },
  {
    title: "Téléphones",
    dataIndex: "phone",
    key: "phone",
    scopedSlots: {
      customRender: "phone",
    },
  },
  {
    title: "État de Paiement mensuelle",
    dataIndex: "status",
    key: "monthlyStatus",
    scopedSlots: {
      customRender: "monthlyStatus",
    },
    onFilter: (value, record) => record.status.indexOf(value) === 0,
  },
  {
    title: "État de Paiement Global",
    dataIndex: "status",
    key: "status",
    scopedSlots: {
      customRender: "status",
    },
    filters: [
      { text: "Payé", value: "payed" },
      { text: "Non payé", value: "impayed" },
    ],
    onFilter: (value, record) => record.status.indexOf(value) === 0,
  },
];

const withNewStatus = (activityFees, activity) => {
  let status = "impayed";
  if (activityFees.inscription || activity.inscription == 0) {
    status = "payed";
    if (!activity.once) {
      for (const [key, value] of Object.entries(activityFees.months)) {
        if (!value) {
          status = "impayed";
          break;
        }
      }
    }
  }
  return { ...activityFees, status: status };
};
const activityFeesFormater = (activityFees, activity) => {
  let status = "impayed";
  if (activityFees.inscription || activity.inscription == 0) {
    status = "payed";
    if (activity.once === false && activityFees.months) {
      for (const [key, value] of Object.entries(activityFees.months)) {
        if (!value) {
          status = "impayed";
          break;
        }
      }
    }
  }
  console.log(activityFees);
  const parent = activityFees.parent[0];
  const student = activityFees.student[0];
  return {
    status,
    _id: activityFees._id,
    fullName: student.firstName + " " + student.lastName,
    parents: parent.father.firstName + " " + parent.father.lastName,
    phone: parent.father.phone,
    classRoom: activityFees.classRoom[0],
    inscription: activityFees.inscription,
    months: activityFees.months,
    start: activityFees.start,
  };
};
const frMonths = {
  1: "Janvier ",
  2: "Février ",
  3: "Mars ",
  4: "Avril ",
  5: "Mai ",
  6: "Juin ",
  7: "Juillet ",
  8: "Août ",
  9: "Septembre ",
  10: "Octobre ",
  11: "November ",
  12: "Décembre ",
};
export default {
  computed: mapState(["settings"]),
  components: {
    "a-page-header": PageHeader,
    "a-descriptions": Descriptions,
  },
  async created() {
    this.tableLeading = true;
    this.form = this.$form.createForm(this, { name: "addActivityForm" });
    apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
          },
        },
      })
      .then((res) => (this.classList = res.data))
      .catch((e) => this.$message.error("Aucunne classe est trouvé"));
    await apiClient
      .post("/activities/filter", {
        query: {
          _id: this.activityId,
          //schoolarYear: this.settings.activeSchoolarYear,
        },
        aggregation: [
          {
            $lookup: {
              from: "services",
              localField: "service",
              foreignField: "_id",
              as: "service",
            },
          },
          {
            $project: {
              once: 1,
              service: { name: 1 },
              inscription: 1,
              monthly: 1,
              start: 1,
              end: 1,
              designation: 1,
              order: 1,
            },
          },
        ],
      })
      .then((res) => (this.activity = res.data[0]))
      .catch((e) => this.$message.error("Activité non trouvé!"));
    this.activityName = this.activity.service[0].name;
    await apiClient
      .post("/students/activity/payment/filter", {
        query: {
          status: "active",
          activity: this.activityId,
        },
        aggregation: [
          {
            $lookup: {
              from: "students",
              localField: "student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: {
                    $arrayElemAt: [
                      `$student.schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                      0,
                    ],
                  },
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "student.parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $project: {
              classRoom: {
                _id: 1,
                name: 1,
              },
              student: {
                firstName: 1,
                lastName: 1,
              },
              parent: {
                father: 1,
                mother: 1,
              },
              inscription: 1,
              months: 1,
              start: 1,
            },
          },
        ],
      })
      .then(
        (res) =>
          (this.activityFees = res.data.map((elem) =>
            activityFeesFormater(elem, this.activity)
          ))
      )
      .catch((e) => {
        console.log(e);
        this.$message.error("Aucun inscription au activité est trouvé: ");
      });
    this.activeDataTable = this.activityFees;
    this.tableLeading = false;
  },
  data() {
    return {
      frMonths,
      columns,
      tableLeading: false,
      activityName: "",
      activity: {},
      activeDataTable: [],
      activityFees: [],
      classList: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
    };
  },
  props: {
    activityId: {
      required: true,
    },
  },
  methods: {
    moment,
    paymentDate(insStart, month) {
      const dayOfMonth = moment(insStart).format("DD");
      const formattedResult = moment(month)
        .set("date", dayOfMonth)
        .format("DD-MM-YYYY");

      return formattedResult;
    },
    handleFilterChangeV2(on, value) {
      const other = on == "classe" ? "month" : "classe";
      let newData = [];
      if (value == "all") {
        this.filters[on] = false;
        newData = this.activityFees.filter((e) => true);
      } else {
        this.filters[on] = true;
        this.filters[`${on}_val`] = value;
        if (on == "classe")
          newData = this.activityFees.filter(
            (elem) => elem.classRoom._id == value
          );
        else {
          newData = [...this.activityFees]
            .filter(
              (elem) =>
                !elem.once &&
                typeof elem.months === "object" &&
                elem.months !== null
            )
            .map((elem) => {
              const months = { ...elem.months };
              let newMonths = {};
              Object.keys(months).forEach((key) => {
                if (
                  parseInt(key.split("-")[1]) ==
                  parseInt(this.filters[`${on}_val`])
                )
                  newMonths[key] = months[key];
              });
              return {
                ...elem,
                months: newMonths,
                status: withNewStatus(
                  {
                    inscription: elem.inscription,
                    months: newMonths,
                  },
                  this.activity
                ),
              };
            })
            .filter((elem) => Object.keys(elem.months).length > 0);
        }
      }
      if (this.filters[other]) {
        if (other == "classe")
          newData = [...newData].filter(
            (elem) => elem.classRoom._id == this.filters[`${other}_val`]
          );
        else {
          newData = [...newData]
            .filter(
              (elem) =>
                !elem.once &&
                typeof elem.months === "object" &&
                elem.months !== null
            )
            .map((elem) => {
              const months = { ...elem.months };
              let newMonths = {};
              Object.keys(months).forEach((key) => {
                if (
                  parseInt(key.split("-")[1]) ==
                  parseInt(this.filters[`${other}_val`])
                )
                  newMonths[key] = months[key];
              });
              return {
                ...elem,
                months: newMonths,
                status: withNewStatus(
                  {
                    inscription: elem.inscription,
                    months: newMonths,
                  },
                  this.activity
                ),
              };
            })
            .filter((elem) => Object.keys(elem.months).length > 0);
        }
      }
      this.activeDataTable = newData;
    },
    handleFilterChange(on, value) {
      const other = on == "classe" ? "classe" : "month";
      var newData = [...this.activityFees];
      if (value == "all") {
        this.filters[on] = false;
      } else {
        this.filters[on] = true;
        this.filters[`${on}_val`] = value;
        if (on == "classe")
          newData = this.activityFees.filter(
            (elem) => elem.classRoom._id == value
          );
        else {
          newData = [];
          newData = [...this.activityFees]
            .filter(
              (elem) =>
                !elem.once &&
                typeof elem.months === "object" &&
                elem.months !== null
            )
            .map((elem) => {
              const months = { ...elem.months };

              let newMonths = {};
              Object.keys(months).forEach((key) => {
                if (
                  parseInt(key.split("-")[1]) ==
                  parseInt(this.filters[`${on}_val`])
                )
                  newMonths[key] = months[key];
              });
              return {
                ...elem,
                months: newMonths,
                status: withNewStatus(
                  {
                    inscription: elem.inscription,
                    months: newMonths,
                  },
                  this.activity
                ),
              };
            })
            .filter((elem) => Object.keys(elem.months).length > 0);
        }
      }
      //handle the other filter if exists (again)
      if (this.filters[other]) {
        if (on != "classe") {
          newData = [];
          newData = [...this.activityFees]
            .map((elem) => {
              const months = { ...elem.months };
              let newMonths = {};
              Object.keys(months).forEach((key) => {
                if (
                  parseInt(key.split("-")[1]) ==
                  parseInt(this.filters[`${other}_val`])
                )
                  newMonths[key] = months[key];
              });
              return {
                ...elem,
                months: newMonths,
                status: withNewStatus(
                  {
                    inscription: elem.inscription,
                    months: newMonths,
                  },
                  this.activity
                ),
              };
            })
            .filter((elem) => Object.keys(elem.months).length > 0);
        }
      }
      this.activeDataTable = newData;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    generatePdf() {
      this.$gtag.event("Imp tab etatPaiementActivite", {
        event_category: "Impression PDF",
        event_label: "activites - etatPaiement section",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      try {
        for (var i = 0; i < arrayOfImages.length; i++) {
          if (this.settings.activeBuilding === arrayOfImages[i].db) {
            var imgData = new Image();
            imgData.src = arrayOfImages[i].logo;
          }
        }
      } catch (error) {
        console.error(error);
      }

      var doc = new JsPDF();
      doc.setFont("Amiri");
      doc.setFontSize(15);
      var today = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var SelectedMonth = !this.activity.once
        ? document.getElementById("selectMonth").innerText
        : "";
      var SelectedClass = document.getElementById("selectClass").innerText;
      var currentMonth = today.toLocaleDateString("fr-FR", { month: "long" });
      var className = this.selectedClass;
      doc.setFontSize(10);

      doc.text(
        130,
        20,
        "Imprimé le : " + " " + today.toLocaleDateString("fr-FR", options)
      );
      doc.setFontSize(15);
      doc.text(
        70,
        50,
        this.filters.month
          ? `Etat des Impayés pour le mois ${SelectedMonth}`
          : `Etat des Impayés pour tous les mois`
      );
      doc.setFontSize(15);
      doc.text(
        80,
        60,
        this.filters.classe
          ? "Etat des paiements - " +
              SelectedClass +
              ", " +
              this.activity.designation
          : "Etat des paiements - " + this.activity.designation
      );
      try {
        doc.addImage(imgData, "JPEG", 10, 10, 40, 40);
      } catch (error) {}
      var arr = [];
      const array = [];
      var counter = 1;
      let colSpan = 0;
      for (let i = 0; i < this.activeDataTable.length; i++) {
        let currentIndex = 0;
        this.activity.inscription === 0 ? (colSpan = 0) : (colSpan = 1);

        if (this.activeDataTable[i].months) {
          for (var key of Object.keys(this.activeDataTable[i].months)) {
            colSpan++;
          }
        }
        if (currentIndex === 0) {
          currentIndex++;
          arr.push({
            rowSpan: colSpan,
            content: counter,
            styles: { valign: "middle" },
          });

          /*arr.push({rowSpan: colSpan,
              content: this.activeDataTable[i].activity,
              styles: { valign: 'middle' }});*/
          arr.push({
            rowSpan: colSpan,
            content: this.activeDataTable[i].parents,
            styles: { valign: "middle" },
          });
          counter++;
        }
        if (this.activity.inscription !== 0) {
          arr.push({ content: "Inscription", styles: { fontStyle: "bold" } });
          this.activeDataTable[i].inscription == true
            ? arr.push({
                content: "payé",
                styles: { textColor: [14, 115, 31], fontStyle: "bold" },
              })
            : arr.push({
                content: "impayé",
                styles: { textColor: [209, 23, 45], fontStyle: "bold" },
              });
          array.push(arr);
          arr = [];
        }

        for (var key in this.activeDataTable[i].months) {
          let mois = new Date(key);
          arr.push(mois.toLocaleDateString("fr-FR", { month: "long" }));
          this.activeDataTable[i].months[key] == true
            ? arr.push({
                content: "payé",
                styles: { textColor: [14, 115, 31], fontStyle: "bold" },
              })
            : arr.push({
                content: "impayé",
                styles: { textColor: [209, 23, 45], fontStyle: "bold" },
              });
          array.push(arr);
          arr = [];
        }
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        head: [["Num", "Nom complet", "Frais", "Etat"]],
        body: array,
        margin: { top: 65 },
        didDrawPage: function (data) {
          // Reseting top margin. The change will be reflected only after print the first page.
          data.settings.margin.top = 10;
        },
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Etat_paiements_activité " + this.activityName + ".pdf");
    },
  },
};
/*
    TODO: fix header's subtile disappears in dark mode
      style="background-color: #F5F5F5; padding: 24px;"

*/
</script>
